<template>
  <div
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
    class="group relative flex w-full h-full overflow-hidden rounded-lg"
    :class="$props.class"
  >
    <div class="relative z-10 h-full w-full" :class="cardPadding">
      <slot></slot>
    </div>
    <ClientOnly>
      <div
        class="pointer-events-none absolute inset-0 rounded-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        :style="{
          background: backgroundStyle,
          opacity: gradientOpacity,
        }"
      />
    </ClientOnly>
    
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  gradientColor?: string
  gradientSize?: number
  gradientOpacity?: number
  cardPadding?: string
  class?: string | string[]
}>(), {
  gradientSize: 200,
  gradientColor: "#262626",
  gradientOpacity: 0.8,
  class: () => [],
  cardPadding: 'p-6 sm:p-8'
});

const mouseX = ref(-props.gradientSize * 10);
const mouseY = ref(-props.gradientSize * 10);

const handleMouseMove = (e: MouseEvent) => {
  const target = e.currentTarget as HTMLElement;
  const rect = target.getBoundingClientRect();
  mouseX.value = e.clientX - rect.left;
  mouseY.value = e.clientY - rect.top;
};

const handleMouseLeave = () => {
  mouseX.value = -props.gradientSize * 10;
  mouseY.value = -props.gradientSize * 10;
};

onMounted(() => {
  mouseX.value = -props.gradientSize * 10;
  mouseY.value = -props.gradientSize * 10;
});

const backgroundStyle = computed(() => {
  return `radial-gradient(
    circle at ${mouseX.value}px ${mouseY.value}px,
    ${props.gradientColor} 0%,
    rgba(0, 0, 0, 0) 70%
  )`;
});
</script>