<template>
  <ClientOnly>
    <div class="w-full md:w-[calc(90%-1rem)]  relative">
        <div class="relative mx-auto" v-auto-animate>
          <span class="mx-2 mt-1 text-2xs text-gray-400 dark:text-gray-500 italic shrink-0">{{ !!searchHistoryQueries.length ? 'eerdere zoekopdrachten' : 'anderen zochten naar' }}</span>
          <span class="relative inline gap-x-2 gap-y-1.5 flex-row flex-wrap items-start justify-start" v-auto-animate>
            <UButton
                v-for="(suggestion, index) in suggestions"
                :key="suggestion"
                variant="ghost" 
                size="2xs"
                :color="currentQuery === suggestion ? 'tertiary' : searchHistoryQueries.includes(suggestion) ? 'secondary' : 'gray'"
                :ui="{
                base: 'shrink-0 select-none whitespace-nowrap'
                }"
                :class="index > maxSuggestions ? 'hidden' : ''"
                @click="setQuery({query: suggestion })"
            >
                {{ suggestion }}
            </UButton>
            <UButton
                v-if="suggestions.length > maxSuggestions"
                variant="soft" 
                size="2xs"
                color="gray"
                :ui="{
                base: 'shrink-0 select-none whitespace-nowrap'
                }"
                @click="openSearchHistory"
                class="relative top-0.5"
                icon="i-heroicons-document-magnifying-glass"
            >
                Bekijk zoekgeschiedenis
            </UButton>
          </span>
          
        </div>
    </div>
    <template #fallback>
        <div class="h-[27.2px]" />
    </template>

    <LazySearchHistory 
      v-model="showSearchHistory" 
      :search-history-key="searchHistoryKey"
      :current-query="currentQuery"
      @select="setQuery"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import type { SearchQuery } from '~~/types';


const props = withDefaults(
  defineProps<{ 
    searchHistoryKey: string, 
    currentQuery: string
    baseSuggestions: string[] 
  }>(),
  {
    baseSuggestions: () => [] as string[]
  }
)

const search = useSearchHistoryStore()
const maxSuggestions = 8

const { isMounted } = useUiState()


const searchHistory = computed(() => search.getSearchHistory(props.searchHistoryKey))
const searchHistoryValues = computed(() => searchHistory.value?.values || [])
const searchHistoryQueries = computed(() => searchHistoryValues.value.map(val => val.query))

const suggestions = computed(() => {
  if (!isMounted.value) return []
  if (!!searchHistoryQueries.value.length) return searchHistoryQueries.value
  return props.baseSuggestions
})


const showSearchHistory = ref(false)

const openSearchHistory = () => {
  showSearchHistory.value = true
}


const emit = defineEmits<{
  (e: 'setQuery', payload: SearchQuery): void
}>()
const setQuery = (payload: SearchQuery) => {
  emit('setQuery', payload)
  const { trackEvent } = useTracking()
  trackEvent('use_search_suggestion', {
    event_category: 'ui',
    event_label: props.searchHistoryKey,
  })
}
</script>

<style>

</style>