<template>
  <UBadge
    :key="id"
    :color="color"
    size="xs"
    variant="subtle"
    class="shrink-0 whitespace-nowrap"
  >
    <span>{{ label }}</span>
    <span v-if="showCount" class="ml-1">({{ count }})</span>
    <UButton 
      icon="i-heroicons-x-mark-16-solid" 
      color="gray" 
      variant="ghost" 
      size="2xs"
      class="ml-1"
      @click.stop="emit('remove', id)"
    />
  </UBadge>
</template>

<script lang="ts" setup>
import type { BadgeColor } from '#ui/types'

const props = withDefaults(
  defineProps<{
    id: string
    label: string
    count?: number
    color: BadgeColor
  }>(), {
    color: 'primary'
  }
)

const showCount = computed(() => typeof props.count === 'number')

const emit = defineEmits<{
  (e: 'remove', value: string): void
}>()
</script>

<style>

</style>