<template>
  <UFormGroup 
    :key="id"
    :label="label" 
    size="md" 
    class="relative"
    :ui="{
      label: { base: 'text-primary-500 dark:text-primary-500'}, 
      container: 'mt-2', 
      description: 'text-xs my-1'
    }"
    :description="description"
  >
    <USelectMenu 
      v-model="localState" 
      :options="options" 
      :multiple="multiple" 
      :placeholder="placeholder || `Kies een of meer ${id}`" 
      :searchable="searchable"
      :searchable-placeholder="searchablePlaceholder"
    >
      <template #option="{ option }">
        <UIcon v-if="option.icon" :name="option.icon" class="w-4 h-4" />
        <span>{{ option.label?.plural || option.label || option }}</span>
        <span v-if="!disableCount" class="ml-1">({{ getFacetCount(option) }})</span>
      </template>
      <template #label>
        <div class="relative flex flex-row gap-1 overflow-scroll no-scrollbar" v-auto-animate>
          <span v-if="!localState.length">{{ placeholder || `Kies een of meer ${id}` }}</Span>
          <DeselectableBadge
            v-for="value in localState"
            color="emerald"
            :id="value.id || value"
            :key="value.id || value"
            :label="getLabel(value)"
            :count="!disableCount ? getFacetCount(value) : undefined"
            @remove="remove"
          />
        </div>
        
      </template>

      <template #empty>
        Geen {{ id || opties }} gevonden
      </template>
      <template #option-empty="{ query }">
        <q>{{ query }}</q> niet gevonden
      </template>
    </USelectMenu>
  </UFormGroup>
</template>

<script lang="ts" setup>

import type { Selectable } from '~~/types';

type BaseProps = {
  id: string
  placeholder?: string
  label: string
  description: string
  disableCount?: boolean
  facets?: Record<string, number>,
  currentFacets?: Record<string, number>,
  searchable?: boolean
  searchablePlaceholder?: string
  multiple?: boolean
}

type StringSelectProps = {
  options: Array<string>
  modelValue: Array<string>
  stringSelect: true
} & BaseProps

type SelectableSelectProps = {
  options: Array<Selectable>
  modelValue: Array<Selectable>
  stringSelect: false
} & BaseProps

const props = withDefaults(
  defineProps<StringSelectProps | SelectableSelectProps>(),
  {
    multiple: true,
    searchablePlaceholder: 'zoek naar opties'
  }
)

const getLabel = (value: Selectable | string): string => {
  if (typeof value === 'string') return value
  return typeof value.label === 'object' ? value.label.plural : value.label
}


const emit = defineEmits<{
  (e: 'update:modelValue', val: Array<Selectable> | Array<string>): void
}>()

// @ts-expect-error
const localState: Ref<Array<string> | Array<Selectable>> = computed({
  get: () => props.options
    .filter(value => {
      if (!props.stringSelect) {
        return props.modelValue.map(s => s.id).includes((value as Selectable).id)
      } else return props.modelValue.includes(value as string)
    })
    .sort((a, b) => {
      if (!props.stringSelect) {
        return props.modelValue.map(s => s.id).indexOf((a as Selectable).id) - props.modelValue.map(s => s.id).indexOf((b as Selectable).id)
      } else return props.modelValue.indexOf(a as string) - props.modelValue.indexOf(b as string)
    }),
  set: (val: Selectable[] | string[]) => emit('update:modelValue', val)
})

const getFacetCount = (value: Selectable | string) => {
  if (!props.facets) return 0
  // @ts-expect-error
  const count = props.facets[typeof value === 'string' ? value : value.id] ||  props.facets[value.label?.plural] || props.facets[value.label]
  if (!!count) return count

  if (typeof value !== 'string' && 'altNames' in value && Array.isArray(value.altNames)) {
    for (let val of value.altNames) {
      if (!!props.facets[val]) return props.facets[val]
    }
  }
  

  return 0
}


const remove = (id: string) => {
  // @ts-expect-error
  localState.value = localState.value.filter(value => !props.stringSelect ? (value as Selectable).id !== id : value !== id)
}

watch(localState, (newVal) => {
  const { trackEvent } = useTracking()
  if (!newVal.length) return
  trackEvent('filter', {
    event_category: 'ui',
    event_label: props.id,
    event_value: newVal.map(item => getLabel(item)).join(',')
  })
}, { deep: true })

</script>

<style>

</style>