<template>
  <UInput
      v-model="localState"
      size="xl"
      icon="i-heroicons-magnifying-glass-16-solid"
      :placeholder="placeholder"
      class="w-full "
      :loading="loading"
      :ui="{
        base: 'text-sm md:text-md min-h-[48px]',
        size: {
            xl: 'text-sm',
        },
        trailing: {
            padding: {
              xl: 'pe-20 md:pe-12',
            },
        },
        padding: {
            xl: 'py-3.5'
        }
      }"
  >
      <div v-if="localState" class="absolute top-1/2 -translate-y-1/2 right-14 sm:right-3 z-10">
        <UTooltip text="verwijder zoekterm">
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-16-solid" @click="reset" />
        </UTooltip>
      </div>
      
  </UInput>
</template>

<script lang="ts" setup>

const props = withDefaults(defineProps<{
  modelValue: string
  placeholder?: string,
  loading?: boolean
  debounce?: number | false
}>(), {
  placeholder: 'hoe kunnen we je helpen?',
  loading: false,
  debounce: 200,
})


const emit = defineEmits(['update:modelValue'])
const localState = computed({
  get: () => props.modelValue,
  set: useDebounceFn((newVal: string) => emit('update:modelValue', newVal), !props.debounce ? 0 : props.debounce)
})

const reset = () => {
  const { trackEvent } = useTracking()
  trackEvent('clear_search_input', {
    event_category: 'ui',
    event_label: 'clear_search_input'
  })
  localState.value = ''
}

</script>
