<template>
  <span 
    class="bg-gradient-to-r inline text-transparent bg-clip-text" 
    :class="[gradientText.from[from], gradientText.to[to]]"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  from?: BaseColor | 'default',
  to?: BaseColor | 'default'
}>(), {
  from: 'default',
  to: 'default',
})


const { gradientText } = useAppConfig().components

</script>