<template>
    <div class="relative grow grid">
      <UInput 
        v-model="localState" 
        placeholder="plaatsnaam" 
        :icon="icons.map" 
        :ui="inputProps" 
        :class="minimal ? 'hidden sm:block sm:min-w-[200px]' : ''"
      />
      <UTooltip text="gebruik huidige locatie" class="absolute" :class="minimal ? 'right-1 top-0 bottom-0 sm:right-2 sm:top-2 sm:bottom-2' : 'right-1 top-1 bottom-1'" >
        <UButton size="md" :icon="icons.gps" square :loading="loading" class="square grid place-items-center" @click="getGeoLocation" />
      </UTooltip>
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{ useToast?: boolean, minimal?: boolean, debounce?: number }>(),
  { debounce: 200 }
)

const { location, updateLocation } = useGeolocation()
const { icons } = useConstants()

const localState = computed({
  get: () => location.value.address,
  set: useDebounceFn((val: string) => updateLocation({address: val }), props.debounce)
})

const { $api } = useNuxtApp()

interface UiProps {
  [key: string]: string | UiProps;
}

const inputProps = computed(() => {
  let obj: UiProps = {
    base: 'h-full',
  }

  if (props.minimal) {
    obj = {
      ...obj,
      color: {
        white: {
          outline: 'bg-main-25 dark:bg-main-600/20 ring-primary-100 dark:ring-primary-700/50'
        }
      },
      icon: {
        base: 'text-primary-200 dark:text-primary-400'
      },
      placeholder: 'placeholder-primary-200 dark:placeholder-primary-400'
    }
  }

  return obj
})

const getCity = ref({address: ''})

const emit = defineEmits<{
  (e: 'error:location', value: string): void
}>()

const toast = useToast()

watch(() => location.value.address, () => emit('error:location', ''))

const handleGeocodingError = (err?: any) => {
  const error = 'We konden je locatie niet bepalen op basis van dit adres'
  if (props.useToast) {
    toast.add({
      icon: 'i-heroicons-exclamation-triangle-20-solid',
      title: error,
      color: 'warn',
      timeout: 8000
    })
  }
  emit('error:location', error)
  loading.value = false
}
watchDebounced(() => location.value.address, async (newVal) => {
  if (newVal && newVal !== getCity.value.address) {
    const { trackEvent } = useTracking()

    trackEvent('geocode', {
      event_category: 'engagement',
      event_label: 'geocode_address',
      event_value: 'anonymous'
    })
    loading.value = true
    try {
      const result: any = await $api('/geo/geocoder', {
        query: {
          address: location.value.address
        }
      })
      if (result?.geometry?.location) {
        updateLocation({
          lat: result.geometry?.location.lat,
          lng: result.geometry?.location.lng
        })
        loading.value = false
      } else {
        handleGeocodingError()
      }
    } catch(err) {
      handleGeocodingError(err)
    }
    
  }
}, { debounce: 800, maxWait: 5000 },)


const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const loading = ref(false)

const getGeoLocation = () => {
  emit('error:location', '')
  navigator.geolocation.getCurrentPosition(
    async (pos: {coords: {latitude: number, longitude: number}}) => {
      const { trackEvent } = useTracking()
      trackEvent('geocode', {
        event_category: 'engagement',
        event_label: 'geocode_coords',
        event_value: 'anonymous'
      })
      loading.value = true
      const crd = pos.coords;
      const result: string = await $api('/geo/get-city', {
        query: {
          lat: crd.latitude,
          lng: crd.longitude
        }
      })
      if (result) {
        getCity.value = {
          address: result
        }

        updateLocation({
          lat: crd.latitude,
          lng: crd.longitude,
          address: result
        })
        loading.value = false

        if (props.minimal) {
          toast.add({
            icon: 'i-heroicons-check-badge-20-solid',
            title: 'Je locatie is ingesteld',
            description: `Volgens onze locatiebepaling bevind je je in ${location.value.address}. Klopt dit niet? Vul dan je locatie handmatig in via de zoekopties.`,
            color: 'success',
            timeout: 8000
          })
        }
      }
    }, (err: any) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      loading.value = false
      if (err.code === 1) {
        const error = 'Je hebt geen toestemming gegeven om je locatie te bepalen. Pas je toestemming aan, of vul je locatie handmatig in.'
        if (props.useToast) {
          toast.add({
            icon: 'i-heroicons-exclamation-triangle-20-solid',
            title: error,
            color: 'warn',
            timeout: 8000
          })
        }
        emit('error:location', error)
      }
      
    }, options)
};
</script>

<style>

</style>