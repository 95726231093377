<template>
  <div class="flex gap-2 py-3 items-center flex-wrap mx-1">
    <p class="italic text-gray-600 dark:text-gray-400 text-sm mr-4">{{ mergedCount }} resultaten gevonden</p>
    <UButton 
      v-for="facet in facetList"
      :ui="{rounded: 'rounded-full'}"
      :color="facet.count > 0 ? 'primary' : 'gray'"
      :variant="useContentTypes && useContentTypes[facet.id] ? 'solid' : 'soft'"
      :label="facet.count + ' ' + (facet.count === 1 ? facet.label.single : facet.label.plural)"
      :disabled="useContentTypes && !useContentTypes[facet.id] && facet.count < 1"
      size="xs"
      :icon="selectedContentTypes.includes(facet.id) ? 'i-heroicons-x-mark-16-solid' : facet.icon"
      @click="selectType(facet.id)"
    />
    <UButton 
      :ui="{rounded: 'rounded-full'}"
      color="teal"
      variant="soft"
      label="bekijk op kaart"
      size="xs"
      class="md:hidden"
      :icon="icons.map"
      @click="scrollToMeta"
    />
    <div class="ml-4 lg:ml-auto">
      <LazySocialShare v-if="useShare" :key="renderKey" color="secondary" mode="results" :url="shareUrl" use-tooltip />
    </div>
    
  </div>
</template>

<script lang="ts" setup>
import type { ContentType } from '~~/types';

const props = withDefaults(defineProps<{
  data?: Record<string, number>
  useFilters?: boolean
  useShare?: boolean
  useContentTypes?: Record<string, boolean>,
  selectedContentTypes?: ContentType["id"][]
}>(), {
  selectedContentTypes: () => []
})

const { contentTypes } = useConstants()
const { scrollToMeta } = useNavigatorSearch()

const { icons } = useConstants()

const { copyCurrentUrlQuery } = useParamHelpers()
const { location, isGeoSearch, currentPositionAsLatLngString } = useGeolocation()
const shareUrl = ref(copyCurrentUrlQuery(isGeoSearch.value ? location.value : undefined))

// NOTE workaround to update shareUrl prop
const renderKey = ref(0)
watch([() => useRoute().fullPath, currentPositionAsLatLngString], () => {
  shareUrl.value = copyCurrentUrlQuery(isGeoSearch.value ? location.value : undefined)
})

const facetList = computed(() => !props.useFilters ? [] : contentTypes.map((content) => {
  return {
    id: content.id,
    count: !props.data ? 0 : props.data[content.id] || 0,
    label: content.label,
    icon: content.icon
  }
}))

const mergedCount = computed (() => {
  if (!props.data) return 0 
  return Object.values(props.data).reduce((cur, acc) => cur + acc, 0)
})

const emit = defineEmits<{
  (e: 'select', value: string): void
}>()

const selectType = (id: ContentType["id"]) => {
  emit('select', id)
  const { trackEvent } = useTracking()
  trackEvent('filter_content_type_from_content_count', {
    event_category: 'ui',
    event_label: id,
  })
}


</script>

<style>

</style>