import type { SearchQuery } from "~~/types"

interface State {
  searchHistory: {
    key: string
    createdAt: Date
    values: SearchQuery[] 
  }[]
}

export const useSearchHistoryStore = defineStore('search_history', {
  // a function that returns a fresh state
  state: (): State => ({
    searchHistory: [],
  }),
  getters: {
    // radiusAsInteger: (state) => parseInt(state.radius),
    getSearchHistory: (state) => {
      return (key: string) => state.searchHistory.find(index => index.key === key)
    },
    searchHistoryQueries: (state) => {
      return (key: string) => {
        const index = state.searchHistory.find(index => index.key === key)
        if (!index) return []
        return index.values
      }
    },
  },
  
  actions: {
    createSearchHistory(key: string, value?: Omit<SearchQuery, 'date'>) {
      if (this.searchHistory.findIndex((item) => item.key === key) === -1) {
        this.searchHistory.push({
          key,
          createdAt: new Date(),
          values: !value ? [] : [{
            ...value,
            date: new Date()
          }]
        })
      }
    },
    addSearchQueryToHistory(payload: { key: string, value: Omit<SearchQuery, 'date'> }) {
      const { key, value } = payload
      const index = this.searchHistory.find(index => index.key === key)
      if (!value.query) return
      // Check if index exists
      if (!!index) {
        // If query is already present, abort
        if (index.values?.map(v => v.query).includes(value.query)) return
        index.values = [
          { ...value, date: new Date() },
          ...index.values
        ]
      } else {
        this.createSearchHistory(key, value)
      }
    },
    resetSearchHistory() {
      this.searchHistory = []
      const toast = useToast()
      toast.add({
        id: 'reset_search_history',
        title: 'Je zoekgeschiedenis is gereset',
        color: 'aux2',
        timeout: 6000
      })
    },
  },  
  persist: {
    pick: [
      'searchHistory',
    ]
  }
})