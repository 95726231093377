<script setup lang="ts">
import type { SearchSettingsOptions, SearchQuery } from '~~/types';

const props = defineProps<{
  tooltipClass?: string
  currentQuery?: string
  options: SearchSettingsOptions
}>()

const showModal = ref(false)
const open = () => {
  showModal.value = true

  const { trackEvent } = useTracking()
  trackEvent('open_filter_panel', {
    event_category: 'ui',
    event_label: "navigator_filters"
  })
}
const close = () => (showModal.value = false)

defineShortcuts({
  meta_o: {
    usingInput: true,
    handler: () => {
      open()
    }
  }
})


const showSearchHistory = ref(false)

const openSearchHistory = () => {
  showSearchHistory.value = true
}

const emit = defineEmits<{
  (e: 'reset'): void
  (e: 'setQuery', payload: SearchQuery): void
}>()

const reset = () => {
  emit('reset')
  close()
}
const setQuery = (payload: SearchQuery) => {
  emit('setQuery', payload)
  close()
}

</script>

<template>
  <UTooltip text="open zoekopties" :shortcuts="['⌘', 'O']" :class="tooltipClass">
    <UButton
      icon="i-heroicons-adjustments-horizontal-20-solid"
      trailing
      color="secondary"
      variant="soft"
      aria-label="Open zoekprofiel"
      size="lg"
      class="grid place-items-center md:flex aspect-square md:aspect-auto"
      @click="open"
    >
      <span class="hidden md:inline">zoekopties</span>
    </UButton>
  </UTooltip>
  <USlideover v-model="showModal">
    <UCard
      :ui="{
        divide: 'divide-y divide-gray-200 dark:divide-gray-700',
        background: 'dark:bg-gray-900',
        header: {
          padding: 'py-4'
        },
        footer: {
          padding: 'py-6'
        },
        shadow: 'shadow-none',
        rounded: 'rounded-none',
        ring: 'ring-0',
        base: 'min-h-full',
        body: { base: 'overflow-y-scroll'}
      }"
    >
      <template #header>
        <div class="flex items-center gap-2 justify-between w-full">
          <h5 class="mb-0">{{options.title}}</h5>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" size="lg" class="shrink-0" @click="close" />
        </div>
      </template>

      <div class="flex flex-col gap-4 md:gap-8 min-h-full" v-auto-animate>
        <div class="flex flex-col gap-4 md:gap-8 grow ">
          <slot />
        </div>
        

        <div 
          v-if="options.useSearchHistory"
          class="pt-4"
        >
          <UButton
            variant="ghost" 
            size="2xs"
            color="gray"
            :ui="{
              base: 'shrink-0 select-none self-start -mx-2'
            }"
            @click="openSearchHistory"
            icon="i-heroicons-document-magnifying-glass"
          >
            Mijn zoekgeschiedenis
          </UButton>
        </div>
        
      </div>
     
      

      <template #footer>
        <div class="flex justify-end">
          <UButtonGroup size="lg">
            <UButton v-if="options.useReset" color="gray" variant="soft" label="wis profiel" :ui="{variant: { soft: 'dark:hover:bg-gray-800'}}" @click="reset" />
            <UButton color="emerald" variant="soft"  label="Opslaan" @click="close" />
          </UButtonGroup>
        </div>
        
      </template>
    </UCard>
    <LazySearchHistory 
      v-if="options.useSearchHistory" 
      v-model="showSearchHistory" 
      :search-history-key="options.searchHistoryKey"
      :current-query="currentQuery"
      @select="setQuery"
    />

  </USlideover>

  
</template>
