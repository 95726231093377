import type { SearchQuery } from "~~/types"

export const useQuery = () => {

  const { value: query, setValue, reset } = useParam<string>(
    {
      key: 's',
      defaultValue: ''
    }
  )

  /**
   * Sets the search query from a SearchQuery object
   * @param val Search query object
   */
  const setQuery = (val: SearchQuery) => setValue(val.query)


  return {
    query,
    setQuery,
    resetQuery: reset
  }
}
