<template>
  <div 
    ref="container" 
    class="transition-colors duration-200 z-20"
    :class="sticky ? 'sticky top-[60px] sm:top-[75.5px]' : ''"
  >
    <div 
      :class="[
        fullWidth ? 'fluid' : '',
        sticky ? 'py-2' : '',
        expand && isMounted && sticky ? 'bg-white dark:bg-gray-900 ' : 'bg-transparent'
      ]"
    >
      <div :class="fullWidth ? 'fluid-inner' : ''">
        <component :is="containerElement" class="relative flex gap-1 sm:gap-2">
          <SearchInput
            v-model="localState" 
            ref="searchBox" 
            :placeholder="placeholder"
            :loading="loading"
            :debounce="debounce"
          />
          <slot name="tools" />
          <SearchSettings 
            v-if="$slots.settings" 
            tooltip-class="w-[48px] md:w-auto h-[48px] md:h-auto" 
            :options="searchSettings"
            :current-query="localState"
            @reset="emit('reset')"
            @set-query="setSearchQuery"
          >
            <LazyClientOnly>
              <slot name="settings" />
            </LazyClientOnly>
          </SearchSettings>

        </component>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { LazyClientOnly } from '#components';
import type { SearchQuery, SearchSettingsOptions } from '~~/types';

const props = withDefaults(
  defineProps<{
    modelValue: string
    /* 
    Sticky ONLY works if the search bar is atop level element with a page.

    for example page.vue
    <template>
      <NuxtLayout name="site">
        <h1>Page title</h1>
        <Search v-model="query" sticky />
      </NuxtLayout>
    </template>
    */
    sticky?: boolean
    fullWidth?: boolean
    placeholder?: string
    debounce?: number | false
    loading?: boolean
    searchSettings?: SearchSettingsOptions
  }>(), 
  {
    sticky: false,
    fullWidth: true,
    placeholder: 'waar ben je naar op zoek?',
    debounce: 200,
    loading: false,
    searchSettings: () => ({
      title: 'Mijn zoekprofiel',
      useSearchHistory: false,
      searchHistoryKey: 'default',
      useReset: true
    })
  }
)


const containerElement = computed(() => props.fullWidth ? resolveComponent('UContainer') : 'div')

const emit = defineEmits(['update:modelValue', 'reset'])
const localState = computed({
  get: () => props.modelValue,
  set: (newVal: string) => emit('update:modelValue', newVal)
})

const setSearchQuery = (payload: SearchQuery) => {
  localState.value = payload.query
}

const searchBox = ref(null)
const { top: searchTop } = useElementBounding(searchBox)
const expand = ref(false)
const { y: windowY } = useWindowScroll()
debouncedWatch(windowY, () => {
  expand.value = searchTop.value <= 85
}, { debounce: 25, maxWait: 50 })

const { isMounted } = useUiState()

</script>
