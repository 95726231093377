import type { Sector, ContentType, Onderwijsvorm, Bevoegdheid, Selectable } from '~~/types';

/**
 * Creates a transform for arrays of Selectable items
 * @param items The array of all possible items to select from
 * @returns A Transform object for the specific Selectable type
 */
function createSelectableArrayTransform<T extends Selectable>(items: T[]): Transform<T[]> {
  return {
    fromUrl: (val: string | string[]) => {
      try {
        // Handle both string and array cases from URL
        const ids = typeof val === 'string' ? [val] : val
        return ids
          .map((id: string) => items.find(item => item.id === id))
          .filter((item: T | undefined): item is T => item !== undefined)
      } catch {
        return []
      }
    },
    toUrl: (val: T[]) => {
      return val.length ? val.map(item => item.id) : undefined
    }
  }
}

function createStringArrayTransform(): Transform<string[]> {
  return {
    fromUrl: (val: string | string[]) => {
      try {
        if (typeof val === 'string') return val.split(',')
        return val
      } catch {
        return []
      }
    },
    toUrl: (val: string[]) => {
      return val.length ? val : undefined
    }
  }
}

export const useNavigatorSearch = () => {

  const scrollToMeta = () => {
    document.getElementById('metaContainerOffset')?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const scrollToResults = () => {
      document.getElementById('resultsContainerOffset')?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const { sectors, contentTypes, onderwijsvormen, bevoegdheden } = useConstants()

  const { value: selectedSectors, reset: resetSectors } = useParam<Sector[]>({
    key: 'sectoren',
    transform: createSelectableArrayTransform(sectors),
    defaultValue: []
  })

  const { value: selectedContentTypes, reset: resetContentTypes } = useParam<ContentType[]>({
    key: 'content',
    transform: createSelectableArrayTransform(contentTypes),
    defaultValue: []
  })

  const addOrRemoveContentType = (id: ContentType['id'], current: ContentType[]) => {
    if (current.map(type => type.id).includes(id)) return current.filter(type => type.id !== id)
    const typeToAdd = contentTypes.find(t => t.id === id)
    if (typeToAdd) {
      return [
        ...current,
        typeToAdd
      ]
    }
    return current
  }

  const { value: selectedSchoolvakken, reset: resetSchoolvakken } = useParam<string[]>({
    key: 'schoolvakken',
    transform: createStringArrayTransform(),
    defaultValue: []
  })

  const { value: selectedInstellingTypes, reset: resetInstellingTypes } = useParam<string[]>({
    key: 'instellingen',
    transform: createStringArrayTransform(),
    defaultValue: []
  })

  const { value: selectedOnderwijsvormen, reset: resetOnderwijsvormen } = useParam<Onderwijsvorm[]>({
    key: 'onderwijsvormen',
    transform: createSelectableArrayTransform(onderwijsvormen),
    defaultValue: []
  })

  const { value: selectedBevoegdheden, reset: resetBevoegdheden } = useParam<Bevoegdheid[]>({
    key: 'bevoegdheden',
    transform: createSelectableArrayTransform(bevoegdheden),
    defaultValue: []
  })

  const getFacetFilter = <T extends { altNames: string[] } | string>(
    selectedItems: { value: T[] },
    defaultKey: string,
    opt?: { key?: string }
  ): string[] => {
    const key = opt?.key || defaultKey;
    return selectedItems.value.flatMap(item => {
      if (typeof item === 'string') return `${key}:${item}`;
      return item.altNames.map(name => `${key}:${name}`);
    });
  };

  const getSectorFacetFilter = (opt?: { key?: string }) => getFacetFilter(selectedSectors, 'sectoren', opt)
  const getSchoolvakkenFacetFilter = (opt?: { key?: string }) => getFacetFilter(selectedSchoolvakken, 'schoolvakken', opt)
  const getInstellingTypesFacetFilter = (opt?: { key?: string }) => getFacetFilter(selectedInstellingTypes, 'soort', opt)
  const getOnderwijsvormenFacetFilter = (opt?: { key?: string }) => getFacetFilter(selectedOnderwijsvormen, 'onderwijsvormen', opt)
  const getBevoegdhedenFacetFilter = (opt?: { key?: string }) => getFacetFilter(selectedBevoegdheden, 'bevoegdheden', opt)

  const resetNavigatorParams = () => {
    resetBevoegdheden()
    resetContentTypes()
    resetInstellingTypes()
    resetOnderwijsvormen()
    resetSchoolvakken()
    resetSectors()
  }


  return {
    resetNavigatorParams,
    selectedSectors,
    getSectorFacetFilter,
    
    scrollToMeta,
    scrollToResults,
    selectedContentTypes,
    addOrRemoveContentType,

    selectedSchoolvakken,
    getSchoolvakkenFacetFilter,

    selectedInstellingTypes,
    getInstellingTypesFacetFilter,

    selectedOnderwijsvormen,
    getOnderwijsvormenFacetFilter,

    selectedBevoegdheden,
    getBevoegdhedenFacetFilter
  }
}

