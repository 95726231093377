<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    withMap?: boolean
    description?: string
  }>(),
  {
    withMap: false,
    description: 'We gebruiken je locatie om zoekresultaten te verfijnen'
  }
)


const { location, clearLocation, isGeoSearch, persistLocation, updateLocation } = useGeolocation()

// Val to prevent calling geocoder directly after setting addres with get-city endpoint
const getCity = ref({address: ''})


const clear = () => {
  clearLocation()
  getCity.value = { address: '' }
}



const locationError = ref('')

const geoOptions = ref([
  "5", "10", "15", "25", "50", "75", "100"
])
const sortedOptions = computed(() => geoOptions.value.sort((a: string, b: string) => parseInt(a) - parseInt(b)))

const selected = computed({
  get: () => location.value.radius,
  set: async (val: string) => {
    if (val && !isNaN(parseInt(val))) {
      let number = parseInt(val)
      if (number > 250) number = 250
      const numberString = number.toString()
      if (!geoOptions.value.includes(numberString)) geoOptions.value.push(numberString)
      updateLocation({
        radius: numberString
      })
    }
  }
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <UFormGroup 
      key="location"
      label="Jouw locatie" 
      size="md" 
      class="relative z-30"
      :ui="{label: { base: 'text-primary-500 dark:text-primary-500'}, container: 'mt-2', description: 'text-xs my-1'}"
      :description="description"
      :error="locationError"
    >
      <div class="flex gap-3 flex-wrap">
        <Address @error:location="(val: string) => locationError = val" />
        <div class="shrink-0">
          <USelectMenu
            v-model="selected"
            :options="sortedOptions"
            creatable
            searchable
            clear-search-on-close
            searchable-placeholder="Afstand"
            class="w-26 h-full"
            size="lg"
            selected-icon="i-heroicons-check-20-solid"
            placeholder="Kies je afstand"
          >
            <template #label>
              <span class="text-xs">{{ selected }} km</span>
            </template>
            <template #option="{ option }">
              <span class="flex-shrink-0">{{ option }} km</span>
            </template>
            <template #option-create="{ option }">
              <span class="flex-shrink-0 text-[10px]">+ voeg {{ option }} km toe</span>
            </template>
          </USelectMenu>
        </div>

      </div>
      
    </UFormGroup>
    <div v-if="isGeoSearch" class="flex gap-4 justify-between items-center flex-wrap gap-y-2">
      <UCheckbox 
        v-model="persistLocation"
        color="emerald"
        name="persist_location" 
        label="Onthou mijn locatie"
      />
      <UButton
        variant="soft" 
        size="2xs"
        color="gray"
        :ui="{
          base: 'shrink-0 select-none self-start'
        }"
        icon="i-heroicons-x-mark-16-solid"
        @click="clear"
      >
        wis locatie
      </UButton>
    </div>
    
    <Map 
      v-if="isGeoSearch && withMap"
      key="map"
      :with-current-location="true"
      :height="180"
    />
  </div>
</template>