<template>
  <section 
    class="grid gap-6"
    :class="layout === 'grid' ? 'md:grid-cols-2 md:gap-10' : ''" 
  >
  <component
    v-for="card in cards"
    :is="component(card.comingSoon)"
    :to="`/${card.slug}/`"
  >
    <SpotlightCard
        class="flex-col items-center justify-center transition-all shadow-md  dark:shadow-black/10 dark:hover:shadow-black/40  border border-secondary-200 dark:border-gray-700 bg-gradient-to-br"
        :class="[card.comingSoon ? 'from-gray-50 to-gray-100 border-none dark:from-gray-800/30  dark:to-gray-800/40' : 'cursor-pointer from-secondary-25 to-secondary-50 dark:from-gray-950 dark:to-gray-950 shadow-secondary-400/10 hover:shadow-secondary-400/40 hover:-translate-y-0.5']"
        :gradient-color="isDark ? card.comingSoon ? _grays[800] : _secondary[950] : card.comingSoon ? _grays[100] : _secondary[100]"
        :card-padding="!!minimal ? 'p-4' : undefined"
      >
        
        <div class="flex flex-col sm:flex-row sm:items-center gap-4">
          <div 
            class="rounded-md grid place-items-center shrink-0 w-10 h-10 sm:w-14 sm:h-14 "
            :class="card.comingSoon ? 'bg-gray-200 dark:bg-gray-800' : 'bg-secondary-100 dark:bg-secondary-200'"
          >
            <UIcon 
              :name="card.icon" 
              class="w-5 h-5 sm:w-6 sm:h-6"
              :class="card.comingSoon ? 'text-gray-500' : 'text-secondary-500'"
            />
          </div>
            <h4 class="mb-0 grow" :class="minimal ? 'sm:text-sm' : ''">{{ card.heading }}</h4>
          </div>
        <div v-if="!minimal" class="mt-6 flex gap-4 gap-y-2 flex-wrap">
          <UBadge v-if="card.comingSoon" size="xs" color="emerald">
            <UIcon name="i-heroicons-sparkles-20-solid" />
            <span class="font-bold ml-1">binnenkort</span>
          </UBadge>
          <UButton 
            v-for="(facet) in card.facets"
            :label="facet.label + ` (${facet.count})`"
            variant="solid"
            color="white"
            size="2xs"
            :ui="{
              color: { white: { solid: 'text-gray-500 dark:text-gray-300 font-normal hover:bg-gray-100' }}
            }"
            @click.prevent="navigateToFacet(facet, card)"
          />
        </div>
        
      </SpotlightCard>
  </component>
      
    </section>
</template>

<script lang="ts" setup>

type CardKey = 'opleiding' | 'onderwijsinstelling' | 'regioloket' | 'school'

const props = withDefaults(defineProps<{
  layout?: 'grid' | 'list'
  minimal?: boolean
  exclude?: Array<CardKey>
  opleidingenFacets?: Record<string, number>
  regiolokettenFacets?: Record<string, number>
  onderwijsinstellingenFacets?: Record<string, number>
}>(), {
  layout: 'grid',
  exclude: () => []
})

import { _grays, _secondary } from '#tailwind-config/theme/colors';

const { isDark } = useTheme()
const { icons, findBevoegdheid, findSector } = useConstants()

const baseCards = [
  {
    key: 'opleiding',
    slug: 'opleidingen',
    heading: 'Lerarenopleidingen en andere mogelijkheden om je bevoegdheid te behalen',
    icon: icons.opleidingen,
    // We need to map param value to type id
    facets: props.opleidingenFacets ? Object.entries(props.opleidingenFacets).map(([key, value]) => ({ label: key.toLowerCase(), count: value, param: `bevoegdheden=${findBevoegdheid(key)?.id}`})) : []
  },
  {
    key: 'onderwijsinstelling',
    slug: 'onderwijsinstellingen',
    heading: "Ontdek de MBO's, hogescholen en universiteiten bij jou in de buurt",
    icon: icons.onderwijsinstelling,
    facets: props.onderwijsinstellingenFacets ? Object.entries(props.onderwijsinstellingenFacets).map(([key, value]) => ({ label: key.toLowerCase(), count: value, param: `instellingen=${key.toLowerCase()}`})) : []
  },
  {
    key: 'regioloket',
    slug: 'regioloketten',
    heading: 'Regioloketten die voor jou een werkplek kunnen vinden',
    icon: icons.regioloket,
    // We need to map param value to type id
    facets: props.regiolokettenFacets ? Object.entries(props.regiolokettenFacets).map(([key, value]) => ({ label: key.toLowerCase(), count: value, param: `sectoren=${findSector(key)?.id}` })) : []
  },
  {
    key: 'school',
    comingSoon: true,
    heading: "Scholen in het primair en voortgezet onderwijs",
    icon: icons.scholen,
    facets: [
      { label: 'primair onderwijs', count: 7031 },
      { label: 'voortgezet onderwijs', count: 1810 },
    ]
  }
]

type Card = typeof baseCards[number]

const cards = computed(() => {
  return baseCards.filter(c => !props.exclude.includes(c.key as CardKey))
})


const component = (comingSoon?: boolean) => {
  if (comingSoon) return 'div'
  return resolveComponent('NuxtLink')
}

const navigateToFacet = (facet: Card["facets"][number], card: Card) => {
  if ('param' in facet) navigateTo(`/${card.slug}/?${facet.param}`)
}

</script>

<style>

</style>